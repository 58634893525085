<template>
    <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                Account disabled.
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            It seems this user's account has been disabled. Please contact one of the WLD admins to discuss.
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    computed: {
        
    }
}
</script>